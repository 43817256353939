import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import SampleSelect from "../components/SampleSelect";
import {
  StaticFields,
  CalcinedSection,
  MilledSection,
  DriedSection,
  SievedSection,
  FormedIntoSlurry,
  dryAged,
  combinedSection,
  newSampleSection,
  millMedia,
  millJarType,
  formedSlurryType1,
  formedSlurryType2,
  formedSlurryType3,
  psdFilesData,
  viewChange,
  ssaFilesData,
  imageFilesData,
  rheologyFilesData,
  slumpFilesData,
  fieldChange,
  trialStatus,
  dscFilesData,
  popupShow,
  viewVisitData,
  uploadFile,
  uploadDriedFile,
  selectSampleDisable,
} from "../../src/recoil/atom";
import { Alert, IconButton } from "@mui/material";
import StaticSample from "../components/StaticSample";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import VerticalTabModal from "../components/VericalTabModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import configParam from "../Config";
import SuccessPopup from "../input/SuccessPopup";
import moment from "moment";
import SampleOption from "../input/SampleOption";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
const ApiURL = configParam.API_URL;

const NewSample = (props) => {
  const [value, setValue] = useRecoilState(fieldChange);
  const [, setSampleDataView] = useRecoilState(viewVisitData);
  const [showFile, setShowFile] = useRecoilState(viewChange);
  const [isLoading, setIsLoading] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const [, setOpen] = useRecoilState(popupShow);
  const staticSample = useRecoilValue(StaticFields);
  const calcinedDet = useRecoilValue(CalcinedSection);
  const milledDet = useRecoilValue(MilledSection);
  const driedDet = useRecoilValue(DriedSection);
  const sievedDet = useRecoilValue(SievedSection);
  const formIntoDet = useRecoilValue(FormedIntoSlurry);
  const dryAgedDet = useRecoilValue(dryAged);
  const combinedDet = useRecoilValue(combinedSection);
  const sampleDet = useRecoilValue(newSampleSection);
  const storedUserData = JSON.parse(localStorage.getItem("loginUserDetails"));
  const millMediaDet = useRecoilValue(millMedia);
  const millJarDet = useRecoilValue(millJarType);
  const [msg, setMsg] = useState("");
  const [popupError, setPopupError] = useState(false);
  const [message, setMessage] = useState(false);
  const formedDetType1 = useRecoilValue(formedSlurryType1);
  const formedDetType2 = useRecoilValue(formedSlurryType2);
  const formedDetType3 = useRecoilValue(formedSlurryType3);
  const PsdMeasureData = useRecoilValue(psdFilesData);
  const SsaMeasureData = useRecoilValue(ssaFilesData);
  const ImageMeasureData = useRecoilValue(imageFilesData);
  const RheMeasureData = useRecoilValue(rheologyFilesData);
  const SlumpMeasureData = useRecoilValue(slumpFilesData);
  const DscMeasureData = useRecoilValue(dscFilesData);
  const uploadFiles = useRecoilValue(uploadFile);
  const uploadDriFiles = useRecoilValue(uploadDriedFile);
  const selectDiable = useRecoilValue(selectSampleDisable);

  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);
  let combinedPromises = [];
  let combinedIds = [];

  // RESET VALUE SECTION
  const resetCalcinedDetValue = useResetRecoilState(CalcinedSection);
  const resetMilledDetValue = useResetRecoilState(MilledSection);
  const resetDriedDetValue = useResetRecoilState(DriedSection);
  const resetSievedDetValue = useResetRecoilState(SievedSection);
  const resetFormedDetValue = useResetRecoilState(FormedIntoSlurry);
  const resetDryAgedDetValue = useResetRecoilState(dryAged);
  const resetNewSampleDetValue = useResetRecoilState(newSampleSection);
  const resetCombinedDetValue = useResetRecoilState(combinedSection);
  const resetPsdDetValue = useResetRecoilState(psdFilesData);
  const resetImgDetValue = useResetRecoilState(imageFilesData);
  const resetSsaDetValue = useResetRecoilState(ssaFilesData);
  const resetSlumpDetValue = useResetRecoilState(slumpFilesData);
  const resetDscDetValue = useResetRecoilState(dscFilesData);
  const resetRheDetValue = useResetRecoilState(rheologyFilesData);
  const [insertVisitData] = useRecoilState(trialStatus);
  const parentRef = useRef(null);
  const staticRef = useRef(null);
  let sampleGeoArray = [];

  if (value === "Calcined") {
    sampleGeoArray.push(Number(calcinedDet?.sample_geometry?.length ?? ""));
    sampleGeoArray.push(Number(calcinedDet?.sample_geometry?.width ?? ""));
    sampleGeoArray.push(Number(calcinedDet?.sample_geometry?.height ?? ""));
  }

  let sampleDriedGeoArray = [];
  if (value === "Dried") {
    sampleDriedGeoArray.push(Number(driedDet?.sample_geometry?.length ?? ""));
    sampleDriedGeoArray.push(Number(driedDet?.sample_geometry?.width ?? ""));
    sampleDriedGeoArray.push(Number(driedDet?.sample_geometry?.height ?? ""));
  }

  const handleChange = (e) => {
    setValue(e.target.value);
    resetCalcinedDetValue();
    resetMilledDetValue();
    resetDriedDetValue();
    resetSievedDetValue();
    resetFormedDetValue();
    resetDryAgedDetValue();
    resetNewSampleDetValue();
    resetCombinedDetValue();
    resetPsdDetValue();
    resetImgDetValue();
    resetSsaDetValue();
    resetSlumpDetValue();
    resetDscDetValue();
    resetRheDetValue();
  };

  const onHandleClosed = () => {
    setMessage(false);
    setPopupError(false);
    setOpen(false);
    getOpenSampleViewData();
  };
  let isCalcined = value === "Calcined";
  let isMilled = value === "Milled";
  let isDried = value === "Dried";
  let isSieved = value === "Sieved";
  let isFormedIntoSlurry = value === "Formed Into Slurry";
  let isDryAged = value === "Dry Aged";
  let isCombined = value === "Combined";
  let isNewArrival = value === "New Arrival";

  const onHandleInsert = () => {
    dataLoad();
  };

  const dataLoad = async () => {
    if (insertVisitData === 0) {
      setIsLoading(true);
      await insertSamplesData();
    } else {
      setIsLoading(true);
      updateSampleData();
    }
  };
  // start InsertDocument Query

  const getNoteId = async () => {
    let noteId = 0;
    const url = ApiURL + "insert_notes";
    try {
      const response = await axios.post(url, {
        note: staticSample["addNotes"],
        updated_at: new Date(),
        headers: {
          authorization: loginUserDetails.web_token
        },
      });

      if (response.data.data.user[0]) {
        noteId = response.data.data.user[0].id;
      }
    } catch (error) {
      console.error("Error inserting note:", error);
    }
    return noteId;
  };

  const insertPSDData = async (val) => {
    let SGID = storedUserData["operator_id"] || "";

    let psdResult = [];
    let arr = Array.isArray(val) ? val : [val];

    const url = ApiURL + "insert_psd_doc";

    await Promise.all(
      arr.map(async (psdData) => {
        try {
          let psdAzureUrl = psdData["filename"] || "";
          let modifyPsdUrl = psdAzureUrl.replace(/\s+/g, "");
          let arrestedTime = psdData["arrested_time"];
          let validArrestedTime = "";
          if (arrestedTime) {
            let parts = arrestedTime.split(":");
            let newParts = parts.filter((part) => part.trim() !== "");
            if (newParts.length < 3) {
              validArrestedTime = null;
            } else {
              validArrestedTime = arrestedTime;
            }
          }
          const response = await axios.post(
            url,
            {
              sample_number: Number(staticSample["sample_number"]),
              operator_id: SGID,
              arrested_sample: psdData["arrested_sample"],
              arrested_time: validArrestedTime,
              legend_name: psdData["legend_name"],
              date: psdData["psdDate"],
              d10: Number(psdData["d10"]),
              d50: Number(psdData["d50"]),
              d90: Number(psdData["d90"]),
              diameter_q_undersize: "",
              refractive_index: psdData["refractive_index"],
              psd_filename: modifyPsdUrl,
              updated_at: new Date().toISOString(),
            },
            {
              headers: {
                authorization: loginUserDetails.web_token,
                // secret_key: loginUserDetails.web_secret
              },
            }
          );

          if (response.data.data.user[0]) {
            psdResult.push(response.data.data.user[0].psd_id);
          } else {
            setPopupError(true);
            setMessage(true);
          }
        } catch (error) {
          console.error("Error inserting psdData:", error);
        }
      })
    );

    return psdResult;
  };

  const insertDscData = async (val) => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let dscResult = [];

    let arr = [];
    if (Array.isArray(val)) {
      arr = val;
    } else {
      arr.push(val);
    }

    const url = ApiURL + "insert_dsc";
    await Promise.all(
      arr.map(async (dscData) => {
        try {
          let dscAzureUrl;
          if (dscData["name"] !== null) {
            dscAzureUrl = dscData.file["name"];
          } else {
            dscAzureUrl = "";
          }
          let modifyDscUrl = dscAzureUrl.replace(/\s+/g, "");
          const response = await axios.post(url, {
            sample_number: Number(staticSample["sample_number"]),
            dsc_filename: modifyDscUrl,
            operator_id: SGID,
            date: dscData["dscDate"],
            alpha_fraction: dscData["alpha_Fraction"],
            beta_fraction: dscData["beta_Fraction"],
            gypsum_fraction: dscData["gypsum_Fraction"],
            updated_at: new Date(),
            headers: {
              authorization: loginUserDetails.web_token,
              //secret_key: loginUserDetails.web_secret
            },
          });
          if (response.data.data.user[0]) {
            dscResult.push(response.data.data.user[0].dsc_id);
          }
        } catch (error) {
          console.error("Error inserting DSCData:", error);
        }
      })
    );
    return dscResult;
  };

  const insertSsaData = async (val) => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    const url = ApiURL + "insert_ssa";
    let ssaResult = [];
    let arr = [];
    if (Array.isArray(val)) {
      arr = val;
    } else {
      arr.push(val);
    }
    await Promise.all(
      arr.map(async (ssaData) => {
        try {
          let ssaAzureUrl;
          if (ssaData["name"] !== null) {
            ssaAzureUrl = ssaData.file["name"];
          } else {
            ssaAzureUrl = "";
          }
          let modifySsaUrl = ssaAzureUrl.replace(/\s+/g, "");
          const response = await axios.post(url, {
            sample_number: staticSample["sample_number"],
            ssa_filename: modifySsaUrl,
            operator_id: SGID,
            surface_area: ssaData["surface_area"],
            date: ssaData["imageDate"],
            updated_at: new Date(),
            headers: {
              authorization: loginUserDetails.web_token,
              //secret_key: loginUserDetails.web_secret
            },
          });

          if (response.data.data.user[0]) {
            ssaResult.push(response.data.data.user[0].ssa_id);
          }
        } catch (error) {
          console.error("Error inserting psdData:", error);
        }
      })
    );
    return ssaResult;
  };

  const insertImageData = async (val) => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let imageResult = [];

    let arr = [];
    if (Array.isArray(val)) {
      arr = val;
    } else {
      arr.push(val);
    }
    const url = ApiURL + "insert_image_doc";
    await Promise.all(
      arr.map(async (imgData) => {
        try {
          let azureImageUrl;
          azureImageUrl = imgData.file["name"];
          let modifyImgsUrl = azureImageUrl.replace(/%20/g, "");

          const response = await axios.post(url, {
            sample_number: staticSample["sample_number"],
            operator_id: SGID,
            img_filename: modifyImgsUrl,
            date: imgData["imageDate"],
            updated_at: new Date(),
            headers: {
              authorization: loginUserDetails.web_token,
              //secret_key: loginUserDetails.web_secret
            },
          });
          if (response.data.data.user[0]) {
            imageResult.push(response.data.data.user[0].media_id);
          }
        } catch (error) {
          console.error("Error inserting psdData:", error);
        }
      })
    );
    return imageResult;
  };

  const insertRheologyData = async (val) => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let rheResult = [];
    let arr = [];
    if (Array.isArray(val)) {
      arr = val;
    } else {
      arr.push(val);
    }
    const url = ApiURL + "insert_rheology";
    await Promise.all(
      arr.map(async (rheData) => {
        try {
          let rheAzureUrl;
          rheAzureUrl = rheData["filename"];
          let modifyRheoUrl = rheAzureUrl
            ? rheAzureUrl.replace(/\s+/g, "")
            : "";
          let time_to_pour = rheData["time_to_pour"];
          let validTimepour = "";
          if (time_to_pour) {
            let parts = time_to_pour.split(":");
            let newParts = parts.filter((part) => part.trim() !== "");
            if (newParts.length < 3) {
              validTimepour = null;
            } else {
              validTimepour = time_to_pour;
            }
          }


          const response = await axios.post(url, {
            sample_number: Number(staticSample["sample_number"]),
            operator_id: SGID,
            date: rheData["rheologyDate"],
            time_to_pour: validTimepour,
            legend_id: rheData["legend_id"],
            rheometer_id: rheData["rheometer_id"],
            time_to_start_measurement: rheData["time_to_start_measurement"],
            rhe_filename: modifyRheoUrl,
            flow_sweep_data: "",
            updated_at: new Date(),
            headers: {
              authorization: loginUserDetails.web_token,
              //secret_key: loginUserDetails.web_secret
            },
          });
          if (response.data.data.user[0]) {
            rheResult.push(response.data.data.user[0].rheology_id);
          }
        } catch (error) {
          console.error("Error inserting psdData:", error);
        }
      })
    );
    return rheResult;
  };

  const insertSlumpData = async (val) => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let slumpResult = [];

    let arr = [];
    if (Array.isArray(val)) {
      arr = val;
    } else {
      arr.push(val);
    }
    const url = ApiURL + "insert_slump";
    await Promise.all(
      arr.map(async (slumpData) => {
        try {
          const response = await axios.post(url, {
            sample_number: staticSample["sample_number"],
            operator_id: SGID,
            date: slumpData["slumpDate"],
            time_to_pour: Number(slumpData["time_to_pour"]),
            lift_speed: Number(slumpData["lift_speed"]),
            time_to_lift: Number(slumpData["time_to_lift"]),
            direction_1_size: Number(slumpData["direction_1_size"]),
            direction_2_size: Number(slumpData["direction_2_size"]),
            fill_weight: Number(slumpData["fill_weight"]),
            headers: {
              authorization: loginUserDetails.web_token,
              //secret_key: loginUserDetails.web_secret
            },
          });
          if (response.data.data.user[0]) {
            slumpResult.push(response.data.data.user[0].slump_id);
          }
        } catch (error) {
          console.error("Error inserting psdData:", error);
        }
      })
    );
    return slumpResult;
  };
  const insertCombinedData = async (sampleNum) => {
    try {
      const combinedPromises = combinedDet.map(async (items) => {
        const combinedUrl = ApiURL + "insert_combined";
        const response = await axios.post(combinedUrl, {
          sample_number: sampleNum,
          parent_ids: items.parent_ids,
          masses: items.masses,
          headers: {
            authorization: loginUserDetails.web_token
          },
        });
        if (response.data.data.res[0]) {
          combinedIds.push(response.data.data.res[0].combined_id);
        }
      });
      await Promise.all(combinedPromises);
      return combinedIds;
    } catch (error) {
      console.error("Error in insertCombinedData:", error);
      throw error;
    }
  };

  const insertDocumentData = async () => {
    let psdIds = [];
    if (PsdMeasureData.length > 0) {
      if (PsdMeasureData[0]["psdDate"]) {
        psdIds = await insertPSDData(PsdMeasureData);
      }
    }

    let ssaIds = [];
    if (SsaMeasureData.length > 0) {
      if (SsaMeasureData[0] && SsaMeasureData[0]["surface_area"]) {
        ssaIds = await insertSsaData(SsaMeasureData);
      }
    }

    let imageIds = [];

    if (ImageMeasureData.length > 0) {
      if (ImageMeasureData[0]["imageDate"]) {
        imageIds = await insertImageData(ImageMeasureData);
      }
    }

    let rheIds = [];
    if (RheMeasureData.length > 0) {
      if (RheMeasureData[0]["rheologyDate"]) {
        rheIds = await insertRheologyData(RheMeasureData);
      }
    }

    let slumpIds = [];
    if (SlumpMeasureData.length > 0) {
      if (SlumpMeasureData[0]["lift_speed"]) {
        slumpIds = await insertSlumpData(SlumpMeasureData);
      }
    }
    let dscIds = [];
    if (DscMeasureData.length > 0) {
      if (DscMeasureData[0]["dscDate"]) {
        dscIds = await insertDscData(DscMeasureData);
      }
    }

    let noteId = "";
    await getNoteId().then((val) => {
      noteId = val;
    });
    // Wait for all promises to complete
    await Promise.all([
      psdIds,
      ssaIds,
      imageIds,
      rheIds,
      slumpIds,
      dscIds,
      combinedIds,
      noteId,
    ]);
    const updateUrl = ApiURL + "update_allDocData";
    // Axios POST request
    await axios
      .post(updateUrl, {
        sample_number: Number(staticSample["sample_number"]),
        image_ids: `{${imageIds.toString()}}`,
        psd_ids: `{${psdIds.toString()}}`,
        rheology_ids: `{${rheIds.toString()}}`,
        slump_ids: `{${slumpIds.toString()}}`,
        ssa_ids: `{${ssaIds.toString()}}`,
        dsc_ids: `{${dscIds.toString()}}`,
        combined_id: `{${combinedIds.toString()}}`,
        note_id: noteId,
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((data) => {
        getOpenSampleViewData();
      });
  };

  const getOpenSampleViewData = () => {
    setIsLoading(true);
    const url = ApiURL + "get_viewSampleTable";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          // //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z, index) => {
            return res.data.data.res[index];
          });
          setSampleDataView(newArr);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  // UpdateQuery DOcument Start
  // Update Psd
  const updatePSDData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let result = [];
    const promises = PsdMeasureData.map(async (val) => {
      if (val.visitStatus === "update") {
        if (val.psd_id) {
          result.push(val.psd_id);
          const url = ApiURL + "update_psd";
          let updateURl = "";
          updateURl = val["filename"];
          let arrestedTime = val["arrested_time"];
          let validArrestedTime = "";
          if (arrestedTime) {
            let parts = arrestedTime.split(":");
            let newParts = parts.filter((part) => part.trim() !== "");

            if (newParts.length < 3) {
              validArrestedTime = null;
            } else {
              validArrestedTime = arrestedTime;
            }
          }
          await axios
            .post(url, {
              psd_id: val.psd_id,
              operator_id: SGID,
              primary_operator_id: SGID,
              arrested_sample: val["arrested_sample"],
              arrested_time: validArrestedTime ? validArrestedTime : null,
              legend_name: val["legend_name"],
              date: val["psdDate"],
              d10: val["d10"] ? val["d10"] : null,
              d50: val["d50"] ? val["d50"] : null,
              d90: val["d90"] ? val["d90"] : null,
              diameter_q_undersize: "",
              refractive_index: val["refractive_index"]
                ? val["refractive_index"]
                : null,
              psd_filename: updateURl,
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token
              },
            })
            .then((res) => {
              if (res.data.rowCount > 0) {
                getOpenSampleViewData();
              }
            });
        }
      } else {
        let arr = [];
        arr.push(val);
        let insertResult = "";
        await insertPSDData(arr).then((val) => {
          insertResult = val;
        });
        result.push(insertResult);
      }
    });
    await Promise.all(promises);
    return result;
  };

  // Update imG
  const updateImageData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let imgResult = [];
    const promises = ImageMeasureData.map(async (val) => {
      if (val.visitStatus === "update") {
        if (val.media_ids) {
          imgResult.push(val.media_ids);
          const url = ApiURL + "update_image_doc";
          let updateAzureImageUrl = "";
          updateAzureImageUrl = val.file["name"];
          await axios
            .post(url, {
              media_id: val.media_ids,
              img_filename: updateAzureImageUrl,
              operator_id: SGID,
              date: val["imageDate"],
              primary_operator_id: SGID,
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
              },
            })
            .then((res) => {
              if (res.data.rowCount > 0) {
                getOpenSampleViewData();
              }
            });
        }
      } else {
        let arr = [];
        arr.push(val);
        let insertResult = ""; // Return the promise from insertPSDData
        await insertImageData(arr).then((val) => {
          insertResult = val;
        });
        imgResult.push(insertResult);
      }
    });
    await Promise.all(promises);
    return imgResult;
  };
  // Update Rheology
  const updateRheologyData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let result = [];
    const promises = RheMeasureData.map(async (val) => {
      if (val.visitStatus === "update") {
        if (val.rheology_id) {
          result.push(val.rheology_id);
          const url = ApiURL + "update_rhe_doc";
          let updateRheAzureUrl = "";
          updateRheAzureUrl = val["filename"];
          await axios
            .post(url, {
              rheology_id: val.rheology_id,
              operator_id: SGID,
              date: val["rheologyDate"],
              time_to_pour: val["time_to_pour"],
              legend_id: val["legend_id"],
              rheometer_id: val["rheometer_id"],
              time_to_start_measurement: val["time_to_start_measurement"],
              flow_sweep_data: null,
              rhe_filename: updateRheAzureUrl.replace(/\s+/g, ""),
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
              },
            })
            .then((res) => {
              if (res.data.rowCount > 0) {
                getOpenSampleViewData();
              }
            });
        }
      } else {
        let arr = [];
        arr.push(val);
        let insertRheResult = "";
        await insertRheologyData(arr).then((val) => {
          insertRheResult = val;
        });
        result.push(insertRheResult);
      }
    });
    await Promise.all(promises);
    return result;
  };

  // Update Slump
  const updateSlumpData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let result = [];
    const promises = SlumpMeasureData.map(async (val) => {
      if (val.visitStatus === "update") {
        if (val.slump_id) {
          result.push(val.slump_id);
          const url = ApiURL + "update_slump";
          await axios
            .post(url, {
              slump_id: val.slump_id,
              operator_id: SGID,
              date: val["slumpDate"],
              time_to_pour: Number(val["time_to_pour"]),
              lift_speed: Number(val["lift_speed"]),
              time_to_lift: Number(val["time_to_lift"]),
              direction_1_size: Number(val["direction_1_size"]),
              direction_2_size: Number(val["direction_2_size"]),
              fill_weight: Number(val["fill_weight"]),
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
              },
            })
            .then((res) => {
              if (res.data.rowCount > 0) {
                getOpenSampleViewData();
              }
            });
        }
      } else {
        let slumpArr = [];
        slumpArr.push(val);
        let insertResult = ""; // Return the promise from insertPSDData
        await insertSlumpData(slumpArr).then((val) => {
          insertResult = val;
        });
        result.push(insertResult);
      }
    });
    await Promise.all(promises);
    return result;
  };
  // Update Ssa
  const updateSsaData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let ssaResult = [];
    const promises = SsaMeasureData.map(async (val) => {
      if (val.visitStatus === "update") {
        if (val.ssa_id) {
          ssaResult.push(val.ssa_id);
          const url = ApiURL + "update_ssa";
          let updateSsaAzureUrl = "";
          updateSsaAzureUrl = val.file["name"];
          await axios
            .post(url, {
              ssa_id: val.ssa_id,
              operator_id: SGID,
              ssa_filename: updateSsaAzureUrl.replace(/\s+/g, ""),
              surface_area: val["surface_area"],
              date: val["imageDate"],
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
              },
            })
            .then((res) => {
              if (res.data.rowCount > 0) {
                getOpenSampleViewData();
              }
            });
        }
      } else {
        let arr = [];
        arr.push(val);
        let insertSsaResult = "";
        await insertSsaData(arr).then((val) => {
          insertSsaResult = val;
        });
        ssaResult.push(insertSsaResult);
      }
    });
    await Promise.all(promises);
    return ssaResult;
  };
  // Update Dsc
  const updateDscData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let dscResult = [];
    const promises = DscMeasureData.map(async (val) => {
      if (val.visitStatus === "update") {
        if (val.dsc_id) {
          dscResult.push(val.dsc_id);
          const url = ApiURL + "update_dsc";
          let updateDscAzureUrl = "";
          updateDscAzureUrl = val.file["name"];
          await axios
            .post(url, {
              dsc_id: val.dsc_id,
              operator_id: SGID,
              date: val["dscDate"],
              dsc_filename: updateDscAzureUrl.replace(/\s+/g, ""),
              alpha_fraction: val["alpha_Fraction"],
              beta_fraction: val["beta_Fraction"],
              gypsum_fraction: val["gypsum_Fraction"],
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
              },
            })
            .then((res) => {
              if (res.data.rowCount > 0) {
                getOpenSampleViewData();
              }
            });
        }
      } else {
        let dscArr = [];
        dscArr.push(val);
        let insertDscResult = ""; // Return the promise from insertPSDData
        await insertDscData(dscArr).then((val) => {
          insertDscResult = val;
        });
        dscResult.push(insertDscResult);
      }
    });
    await Promise.all(promises);
    return dscResult;
  };
  // ALL SAMPLES DATA INSERT HERE
  const insertSamplesData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    const url = ApiURL + "insert_testSampleData";
    if (isCalcined) {
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids,
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          note_id: `{}`,
          time_calcined: calcinedDet["time"],
          power: Number(calcinedDet["power"]),
          frequency: Number(calcinedDet["frequency"]),
          sample_mass: Number(calcinedDet["sample_mass"]),
          location: calcinedDet["location"],
          sample_geometry_calcined: `{${sampleGeoArray.toString()}}`,
          sample_packing: Number(calcinedDet["sample_packing"]),
          sample_temperature_measured_calcined:
            calcinedDet["sample_temperature_measured"],
          raw_file: uploadFiles,
          calcination_start_time:
            moment(calcinedDet["calcination_start_time"]).format("YYYY-MM-DD") +
            "T" +
            calcinedDet["calci_time"],
          updated_at: new Date(),
          type: "Calcined",
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);
            setMessage(true);
            setMsg("Calcined submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isDryAged) {
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: `{}`,
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids,
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          aging_duration: dryAgedDet["aging_duration"],
          updated_at: new Date(),
          type: "Dry Aged",
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);
            setMessage(true);
            setMsg("DryAged submitted successfully");
          } else {
            setPopupError(true);
            setMessage(false);
            setIsLoading(false);
          }
        });
    } else if (isMilled) {
      console.log(milledDet);
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"]
            ? staticSample["oddity_flag"]
            : false,
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids,
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          starting_material_id: Number(milledDet["starting_material_id"]),
          media_mass: Number(milledDet["media_mass"]),
          rpm: Number(milledDet["rpm"]),
          duration: milledDet["duration"],
          sample_mass: Number(milledDet["sample_mass"]),
          material: millMediaDet["material"],
          shape: millMediaDet["shape"],
          materialjar: millJarDet["material"],
          jartype: millJarDet["type"],
          volume: parseFloat(millJarDet["volume"]),
          diameter: parseFloat(millJarDet["diameter"]),
          type: "Milled",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);

            setMessage(true);
            setMsg("Milled submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
            setIsLoading(false);
          }
        });
    } else if (isDried) {
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids,
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          fan_speed: Number(driedDet["fan_speed"]),
          oven_temperature: Number(driedDet["oven_temperature"]),
          relative_humidity: Number(driedDet["relative_humidity"]),
          initial_sample_weight: Number(driedDet["initial_sample_weight"]),
          final_sample_weight: Number(driedDet["final_sample_weight"]),
          sample_geometry: `{${sampleDriedGeoArray.toString()}}`,
          sample_temperature_measured2: driedDet["sample_temperature_measured"],
          time2: driedDet["time"],
          raw_file: uploadDriFiles,
          type: "Dried",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);
            setMessage(true);
            setMsg("Dried submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
            setIsLoading(false);
          }
        });
    } else if (isSieved) {
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"]
            ? staticSample["entry_date"]
            : "2019-03-01 06:00:00+00",
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample["additional_operator_ids"],
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          upper_bin_size: Number(sievedDet["upper_bin_size"]),
          lower_bin_size: Number(sievedDet["lower_bin_size"]),
          sieve_type: sievedDet["sieve_type"],
          sieve_duration: Number(sievedDet["sieve_duration"]),
          sieve_pressure: Number(sievedDet["sieve_pressure"]),
          mass_before: Number(sievedDet["mass_before"]),
          mass_retained: Number(sievedDet["mass_retained"]),
          mass_through: Number(sievedDet["mass_through"]),
          type: "Sieved",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);
            setMessage(true);
            setMsg("Sieved submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
            setIsLoading(false);
          }
        });
    } else if (isFormedIntoSlurry) {
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids,
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          water_gauge: Number(formIntoDet["water_gauge"]),
          retarder_amount: Number(formIntoDet["retarder_amount"]),
          power_setting: Number(formIntoDet["power_setting"]),
          duration_of_mixing: formIntoDet["duration_of_mixing"],
          rheology_modifier_amount: Number(
            formIntoDet["rheology_modifier_amount"]
          ),
          additives: formIntoDet["additives"],
          solid_loading: Number(formIntoDet["solid_loading"]),
          retarder_id: (formedDetType3["retarder_id"] === "") ? formedDetType3["retarder_id"] : formedDetType3.retarder_id,
          mixing_equipment_id: (formedDetType2["mixing_equipment_id"] === "") ? formedDetType2["mixing_equipment_id"] : formedDetType2.mixing_equipment_id,
          rheology_modifier_id: (formedDetType1["rheology_modifier_id"] === "") ? formedDetType1["rheology_modifier_id"] : formedDetType1.rheology_modifier_id,
         retardertype: formedDetType3["type3"],
          mix_equipmentType: formedDetType2["type2"],
          rheology_modifiertype: formedDetType1["type1"],
          type: "Formed Into Slurry",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);
            setMessage(true);
            setMsg("FormedIntoSlurry submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isCombined) {
      setIsLoading(true);
      let combinedIds = [];
      let combinedPromises = [];
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids,
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          type: "Combined",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token
          },
        })
        .then((res) => {
          if (res.data.sample) {
            insertCombinedData(Number(staticSample["sample_number"]));
          }
        })
        .then(() => {
          insertDocumentData();
          getOpenSampleViewData();
          setIsLoading(false);
          setMessage(true);
          setMsg("Combined submitted successfully");
        })
        .catch((error) => {
          console.error("Error while making the request:", error);
          setPopupError(true);
          setMessage(true);
        });
      return Promise.all(combinedPromises).then(() => combinedIds);
    } else if (isNewArrival) {
      setIsLoading(true);
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          image_ids: `{}`,
          primary_operator_id: SGID,
          additional_operator_ids: staticSample.additional_operator_ids
            ? staticSample.additional_operator_ids
            : "",
          psd_ids: `{}`,
          rheology_ids: `{}`,
          slump_ids: `{}`,
          ssa_ids: `{}`,
          dsc_ids: `{}`,
          origin: sampleDet["origin"],
          type4: sampleDet["type"],
          lot_number: Number(sampleDet["lot_number"]),
          date_shipped: sampleDet["date_shipped"],
          received_at: sampleDet["received_at"],
          date_received: sampleDet["date_received"],
          type: "New Arrival",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 > 0) {
            insertDocumentData();
            getOpenSampleViewData();
            setIsLoading(false);
            setMessage(true);
            setMsg("NewArrival submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
            setIsLoading(false);
          }
        });
    }
  };

  const updateNoteId = async () => {
    const url = ApiURL + "update_notes";
    axios
      .post(url, {
        id: staticSample["note_id"],
        note: staticSample["addNotes"],
        updated_at: new Date(),
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => { });
  };
  const updateCombined = async () => {

    let results = [];

    const url = ApiURL + "update_Combined";
    await Promise.all(
      combinedDet.map(async (val) => {
        if (val.combined_id) {
          try {
            const response = await axios.post(url, {
              parent_ids: val["parent_ids"],
              masses: val["masses"],
              combined_id: val["combined_id"],
              updated_at: new Date(),
              headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
              },
            });
            results.push(response.data);
          } catch (error) {
            results.push({ error: error.message });
          }
        } else {
          const isDataInserted = results.some(
            (result) => result.combined_id === val.combined_id
          );
          if (!isDataInserted) {
            insertCombinedData(Number(staticSample["sample_number"]));
            results.push({ message: "Data inserted" });
          }
        }
      })
    );
    return results;
  };

  // ALL SAMPLES DATA UPDATE HERE
  const updateSampleData = async () => {
    let SGID = "";
    if (storedUserData["operator_id"]) {
      SGID = storedUserData["operator_id"];
    }
    let updatePsdIds = [];
    if (PsdMeasureData.length > 0) {
      await updatePSDData().then((val) => {
        updatePsdIds = val;
      });
    }
    let updateImgIds = [];
    if (ImageMeasureData.length > 0) {
      await updateImageData().then((val) => {
        updateImgIds = val;
      });
    }
    let updateRheologyIds = [];
    if (RheMeasureData.length > 0) {
      await updateRheologyData().then((val) => {
        updateRheologyIds = val;
      });
    }

    let updateSlumpIds = [];
    if (SlumpMeasureData.length > 0) {
      await updateSlumpData().then((val) => {
        updateSlumpIds = val;
      });
    }
    let ssaIds = [];
    if (SsaMeasureData.length > 0) {
      await updateSsaData().then((val) => {
        ssaIds = val;
      });
    }
    let dscIds = [];
    if (DscMeasureData.length > 0) {
      await updateDscData().then((val) => {
        dscIds = val;
      });
    }
    updateNoteId();
    if (isCombined) {
      updateCombined();
    }
    const url = ApiURL + "update_SampleData";
    if (isCalcined) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          additional_operator_ids: staticSample["additional_operator_ids"],
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          time_calcined: calcinedDet["time"],
          power: Number(calcinedDet["power"]),
          frequency: Number(calcinedDet["frequency"]),
          sample_mass: Number(calcinedDet["sample_mass"]),
          location: calcinedDet["location"],
          sample_geometry_calcined: `{${sampleGeoArray.toString()}}`,
          sample_packing: Number(calcinedDet["sample_packing"]),
          sample_temperature_measured_calcined:
            calcinedDet["sample_temperature_measured"],
          primary_operator_id: SGID,
          raw_file: uploadFiles,
          calcination_start_time:
            calcinedDet["calcination_start_time"] +
            " " +
            calcinedDet["calci_time"],
          type: "Calcined",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("Calcined Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isDryAged) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          primary_operator_id: SGID,
          additional_operator_ids: staticSample["additional_operator_ids"],
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          aging_duration: dryAgedDet["aging_duration"],
          type: "Dry Aged",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("DryAged Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(false);
          }
        });
    } else if (isMilled) {
      console.log(milledDet);
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";

      const requestData = {
        sample_number: Number(staticSample["sample_number"]),
        operation_id: value,
        operator_id: SGID,
        entry_date: staticSample["entry_date"]
          ? staticSample["entry_date"]
          : "2019-03-01 06:00:00+00",
        parent_id: Number(staticSample["parent_id"]),
        oddity_flag: staticSample["oddity_flag"] ? staticSample["oddity_flag"] : false,
        moisture_content: Number(staticSample["moisture_content"]),
        note_id: Number(staticSample["note_id"]),
        additional_operator_ids: staticSample["additional_operator_ids"],
        primary_operator_id: SGID,
        psd_ids: psdIdsString,
        image_ids: imgIdsString,
        rheology_ids: rheologyIdsString,
        slump_ids: slumpIdsString,
        ssa_ids: ssaIdsString,
        dsc_ids: dscIdsString,
        starting_material_id: Number(milledDet["starting_material_id"]),
        media_mass: Number(milledDet["media_mass"]),
        rpm: Number(milledDet["rpm"]),
        duration: milledDet["duration"],
        sample_mass: Number(milledDet["sample_mass"]),
        material: millMediaDet["material"],
        shape: millMediaDet["shape"],
        material1: millJarDet["material"],
        type1: millJarDet["type"],
        volume: parseFloat(millJarDet["volume"]),
        diameter: parseFloat(millJarDet["diameter"]),
        type: "Milled",
        media_id: (millMediaDet.media_id === "") ? millMediaDet.media_id : milledDet.media_Ids,
        jar_id: (millJarDet.jar_id === "") ? millJarDet.jar_id : milledDet.jar_id,
        updated_at: new Date(),
      };
      console.log(millMediaDet, milledDet, requestData);
      // Log the request data
      axios.post(url, requestData, {
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("Milled Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isDried) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"]
            ? staticSample["entry_date"]
            : "2019-03-01 06:00:00+00",
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          additional_operator_ids: staticSample["additional_operator_ids"],
          primary_operator_id: SGID,
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          fan_speed: Number(driedDet["fan_speed"]),
          oven_temperature: Number(driedDet["oven_temperature"]),
          relative_humidity: Number(driedDet["relative_humidity"]),
          initial_sample_weight: Number(driedDet["initial_sample_weight"]),
          final_sample_weight: Number(driedDet["final_sample_weight"]),
          raw_file: uploadDriFiles,
          sample_geometry: `{${sampleDriedGeoArray.toString()}}`,
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
          sample_temperature_measured1: driedDet["sample_temperature_measured"],
          time_dried: driedDet["time"],
          type: "Dried",
          updated_at: new Date(),
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("Dried submitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isSieved) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"]
            ? staticSample["entry_date"]
            : "2019-03-01 06:00:00+00",
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          additional_operator_ids: staticSample["additional_operator_ids"],
          primary_operator_id: SGID,
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
          upper_bin_size: Number(sievedDet["upper_bin_size"]),
          lower_bin_size: Number(sievedDet["lower_bin_size"]),
          sieve_type: sievedDet["sieve_type"],
          sieve_duration: Number(sievedDet["sieve_duration"]),
          sieve_pressure: Number(sievedDet["sieve_pressure"]),
          type: "Sieved",
          mass_before: Number(sievedDet["mass_before"]),
          mass_retained: Number(sievedDet["mass_retained"]),
          mass_through: Number(sievedDet["mass_through"]),
          updated_at: new Date(),
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("Sieved Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isFormedIntoSlurry) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";
      console.log(formedDetType3, formedDetType2, formedDetType1, formIntoDet)
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"]
            ? staticSample["entry_date"]
            : "2019-03-01 06:00:00+00",
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          additional_operator_ids: staticSample["additional_operator_ids"],
          primary_operator_id: SGID,
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
          water_gauge: Number(formIntoDet["water_gauge"]),
          retarder_amount: Number(formIntoDet["retarder_amount"]),
          power_setting: Number(formIntoDet["power_setting"]),
          duration_of_mixing: formIntoDet["duration_of_mixing"],
          rheology_modifier_amount: Number(
            formIntoDet["rheology_modifier_amount"]
          ),
          additives: formIntoDet["additives"],
          solid_loading: Number(formIntoDet["solid_loading"]),
          retarder_id: (formedDetType3["retarder_id"] === "") ? formedDetType3["retarder_id"] : formedDetType3.retarder_id,
          mixing_equipment_id: (formedDetType2["mixing_equipment_id"] === "") ? formedDetType2["mixing_equipment_id"] : formedDetType2.mixing_equipment_id,
          rheology_modifier_id: (formedDetType1["rheology_modifier_id"] === "") ? formedDetType1["rheology_modifier_id"] : formedDetType1.rheology_modifier_id,
          type1: formedDetType1["type1"],
          type2: formedDetType2["type2"],
          type3: formedDetType3["type3"],
          type: "Formed Into Slurry",
          updated_at: new Date(),
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("FormedIntoSlurry Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isCombined) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";

      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"]
            ? staticSample["oddity_flag"]
            : false,
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          additional_operator_ids: staticSample["additional_operator_ids"],
          primary_operator_id: SGID,
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          type: "Combined",
          updated_at: new Date(),
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("Combined Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    } else if (isNewArrival) {
      const psdIdsString = updatePsdIds ? `{${updatePsdIds.toString()}}` : "";
      const imgIdsString = updateImgIds ? `{${updateImgIds.toString()}}` : "";
      const rheologyIdsString = updateRheologyIds
        ? `{${updateRheologyIds.toString()}}`
        : "";
      const slumpIdsString = updateSlumpIds
        ? `{${updateSlumpIds.toString()}}`
        : "";
      const ssaIdsString = ssaIds ? `{${ssaIds.toString()}}` : "";
      const dscIdsString = dscIds ? `{${dscIds.toString()}}` : "";
      axios
        .post(url, {
          sample_number: Number(staticSample["sample_number"]),
          operation_id: value,
          operator_id: SGID,
          entry_date: staticSample["entry_date"],
          parent_id: Number(staticSample["parent_id"]),
          oddity_flag: staticSample["oddity_flag"],
          moisture_content: Number(staticSample["moisture_content"]),
          note_id: Number(staticSample["note_id"]),
          primary_operator_id: SGID,
          psd_ids: psdIdsString,
          image_ids: imgIdsString,
          rheology_ids: rheologyIdsString,
          slump_ids: slumpIdsString,
          ssa_ids: ssaIdsString,
          dsc_ids: dscIdsString,
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
          additional_operator_ids: staticSample["additional_operator_ids"],
          origin: sampleDet["origin"],
          type1: sampleDet["type"],
          lot_number: Number(sampleDet["lot_number"]),
          date_shipped: sampleDet["date_shipped"] ? sampleDet["date_shipped"] : null,
          received_at: sampleDet["received_at"] ? sampleDet["received_at"] : null,
          date_received: sampleDet["date_received"],
          type: "New Arrival",
          updated_at: new Date(),
        })
        .then((res) => {
          if (res.data.rowCount1 || res.data.rowCount2 > 0) {
            setIsLoading(false);
            getOpenSampleViewData();
            setMessage(true);
            setMsg("NewArrival Resubmitted successfully");
          } else {
            setPopupError(true);
            setMessage(true);
          }
        });
    }
  };

  const handleButtonBackClick = () => {
    setShowFile(false);
  };

  const handleNextClick = () => {
    parentRef.current.validationCheck();
    staticRef.current.staticErrorChange();
  };

  const options = [
    "Calcined",
    "Dried",
    "Sieved",
    "Dry Aged",
    "Combined",
    "New Arrival",
    "Formed Into Slurry",
    "Milled"

  ];
  return (
    <div>
      <Dialog
        open={props.open}
        maxWidth="md"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isLoading && (
          <div className="loader">
            <CircularProgress />{" "}
          </div>
        )}
        <div
          style={{
            display: "block",
            width: "100%",
            padding: "16px 16px 0",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Grid item style={{ textAlign: "Right" }}>
            <Tooltip title="Close">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={props.handleClose}
              />
            </Tooltip>
          </Grid>
          {showFile ? (
            <Grid
              container
              style={{
                width: "100%",
                paddingBottom: "16px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                style={{ width: 100, height: 40, marginRight: 20 }}
                onClick={handleButtonBackClick}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{ width: 100, height: 40, backgroundColor: "#270659" }}
                onClick={onHandleInsert}
              >
                Submit
              </Button>{" "}
            </Grid>
          ) : (
            <Grid
              container
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item sm="5" xs="6">
                <SampleOption
                  lableVarient="body2"
                  isLable={true}
                  disabled={selectDiable}
                  lableValue="Select Sample"
                  value={value}
                  options={options}
                  onChange={(e) => handleChange(e)}
                  top
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ width: "100px", height: 40 }}
                  onClick={handleNextClick}
                  endIcon={<NavigateNextIcon />}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
        </div>

        {popupMsg && (
          <Alert
            style={{ width: "100%", marginTop: 10 }}
            variant="filled"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPopupMsg("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {popupMsg}
          </Alert>
        )}

        {message || popupError ? (
          <SuccessPopup
            style={{ width: 400 }}
            open={message || popupError}
            onClose={onHandleClosed}
            popupError={popupError}
            msg={msg}
          />
        ) : null}

        {showFile ? (
          <VerticalTabModal />
        ) : (
          <DialogContent style={{ padding: "16px 0" }}>
            <Grid container xs={12} justifyContent={"space-between"}>
              <Grid item md={6} xs={12} style={{ padding: "0 16px" }}>
                <Typography style={{ fontSize: "18px" }} variant="h6">
                  New Sample
                </Typography>
                <StaticSample ref={staticRef} popupMsg={popupMsg} />
              </Grid>
              <Grid item md={6} xs={12} style={{ padding: "0 16px" }}>
                <Typography style={{ fontSize: "18px" }} variant="h6">
                  Sample Information
                </Typography>
                <SampleSelect
                  value={value}
                  ref={parentRef}
                  showFile={showFile}
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};
export default NewSample;
