import React, { useState, useEffect } from "react";
import configParam from "../Config";
import { ReactComponent as Edit } from "../asset/Edit.svg";
import { ReactComponent as Delete } from "../asset/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { getConvertedJSON } from "../recoil/selector";
import {
  StaticFields,
  CalcinedSection,
  MilledSection,
  DriedSection,
  SievedSection,
  FormedIntoSlurry,
  dryAged,
  combinedSection,
  newSampleSection,
  millMedia,
  millJarType,
  viewChange,
  selectedVisitObj,
  popupShow,
  fieldChange,
  formedSlurryType1,
  formedSlurryType2,
  formedSlurryType3,
  trialStatus,
  viewVisitData,
  psdFilesData,
  imageFilesData,
  rheologyFilesData,
  slumpFilesData,
  ssaFilesData,
  dscFilesData,
  selectSampleDisable,
  jwtToken,
  user,
} from "../recoil/atom";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Box } from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SuccessPopup from "../input/SuccessPopup";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { Login } from "@mui/icons-material";
const ApiURL = configParam.API_URL;
const GetNewSampleData = (props) => {
  const [sampleDataView, setSampleDataView] = useRecoilState(viewVisitData);
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectVisitData, setSelectVisitData] =
    useRecoilState(selectedVisitObj);
  const [, setSelectupdateVisitData] = useRecoilState(trialStatus);
  const convertedJSON = useRecoilValue(getConvertedJSON);
  const [, setcalcinedData] = useRecoilState(CalcinedSection);
  const [, setPsdMeasureData] = useRecoilState(psdFilesData);
  const [, setImgMeasureData] = useRecoilState(imageFilesData);
  const [, setrheMeasureData] = useRecoilState(rheologyFilesData);
  const [, setSlumpMeasureData] = useRecoilState(slumpFilesData);
  const [, setSsaMeasureData] = useRecoilState(ssaFilesData);
  const [, setDscMeasureData] = useRecoilState(dscFilesData);
  const [, setdryAgedData] = useRecoilState(dryAged);
  const [, setCombainedData] = useRecoilState(combinedSection);
  const [, setNewArrivalData] = useRecoilState(newSampleSection);
  const [, setSeivedData] = useRecoilState(SievedSection);
  const [, setDrieddData] = useRecoilState(DriedSection);
  const [, setMilleddData] = useRecoilState(MilledSection);
  const [, setmillMedia] = useRecoilState(millMedia);
  const [, setmillJar] = useRecoilState(millJarType);
  const [, setFormedSlurrydData] = useRecoilState(FormedIntoSlurry);
  const [, setFormedSlurryType1] = useRecoilState(formedSlurryType1);
  const [, setFormedSlurryType2] = useRecoilState(formedSlurryType2);
  const [, setFormedSlurryType3] = useRecoilState(formedSlurryType3);
  const [, setStaticData] = useRecoilState(StaticFields);
  const [, setOpen] = useRecoilState(popupShow);
  const [deleteRow, setDeleteRow] = useState("");
  const [, setMessage] = useState(false);
  const [msg, setMsg] = useState("");
  const [, setOpenDoc] = useRecoilState(viewChange);
  const [show, setShow] = useState(false);
  const [, setCurrFieldChange] = useRecoilState(fieldChange);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);
  const [selectDisable, setSelectDisable] = useRecoilState(selectSampleDisable);
  const columns = [
    {
      field: "ID",
      headerName: "S.No.",
      width: 100,
      editable: false,
    },

    {
      field: "sample_number",
      headerName: "Sample Number",
      width: 200,
      editable: false,
    },
    {
      field: "operator",
      headerName: "Operator",
      width: 200,
      editable: false,
    },
    {
      field: "parent_id",
      headerName: "Parent Id",
      width: 200,
      editable: false,
    },
    {
      field: "operation_id",
      headerName: "Sample Type",
      width: 200,
      editable: false,
    },

    {
      field: "created_at",
      headerName: "Created On",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return moment(params.row.created_at).format("DD-MMM-YYYY");
      }
    },    

    {
      field: "Action",
      headerName: "Actions",
      sortable: false,
      width: 200,
      disableClickEventBubbling: false,
      disableColumnMenu: true,
      renderCell: (items, index) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Edit">
              <Edit
                style={{ marginRight: 20, cursor: "pointer" }}
                onClick={() => onHandleEdit(items, index)}
              />
            </Tooltip>
            <Tooltip title="Add Measurement">
              <AddTaskIcon
                style={{ marginRight: 20, cursor: "pointer" }}
                onClick={() => handleDocEdit(items)}
              />
            </Tooltip>
            {loginUserDetails.role === "Admin" && (
              <Tooltip title="Delete">
                <Delete
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={() => rowDelete(items, index)}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getOpenSampleViewData();
  }, []);

  useEffect(() => {
    if (selectVisitData && selectVisitData.length > 0) {
      let tempConvertedJSON = convertedJSON;
      setcalcinedData(tempConvertedJSON[0].CalcinedSection);
      setStaticData(tempConvertedJSON[0].StaticFields);
      setdryAgedData(tempConvertedJSON[0].dryAged);
      setCombainedData(tempConvertedJSON[0].combinedSection.data);
      setNewArrivalData(tempConvertedJSON[0].newSampleSection);
      setSeivedData(tempConvertedJSON[0].SievedSection);
      setDrieddData(tempConvertedJSON[0].DriedSection);
      setMilleddData(tempConvertedJSON[0].MilledSection);
      setmillMedia(tempConvertedJSON[0].millMedia);
      setmillJar(tempConvertedJSON[0].millJarType);
      setFormedSlurrydData(tempConvertedJSON[0].FormedIntoSlurry);
      setFormedSlurryType1(tempConvertedJSON[0].formedSlurryType1);
      setFormedSlurryType2(tempConvertedJSON[0].formedSlurryType2);
      setFormedSlurryType3(tempConvertedJSON[0].formedSlurryType3);
      setPsdMeasureData(tempConvertedJSON[0].psdFilesData.data);
      setImgMeasureData(tempConvertedJSON[0].imageFilesData.data);
      setrheMeasureData(tempConvertedJSON[0].rheologyFilesData.data);
      setSlumpMeasureData(tempConvertedJSON[0].slumpFilesData.data);
      setSsaMeasureData(tempConvertedJSON[0].ssaFilesData.data);
      setDscMeasureData(tempConvertedJSON[0].dscFilesData.data);
    }
  }, [selectVisitData]);

  const getOpenSampleViewData = () => {
    setIsLoading(true);
    const url = ApiURL + "get_viewSampleTable";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          // //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z, index) => {
            return res.data.data.res[index];
          });
          setSampleDataView(newArr);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const onHandleEdit = (items, index) => {
    setSelectupdateVisitData(1);
    setSelectDisable(true);
    const selectUrl = ApiURL + "get_selected_sampleData";
    axios
      .get(selectUrl, {
        params: {
          sample_number: Number(items.row.sample_number),
          sampleType: items.row.operation_id,
        },
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.user) {
          console.log(res.data);
          setSelectVisitData(res.data.data.user);
          setCurrFieldChange(res.data.data.user[0].operation_id);
        }
        setOpenDoc(false);
        setOpen(true);
      });
  };

  const onClose = () => {
    setOpenAlert(false);
  };

  const rowDelete = (row, index) => {
    setDeleteRow(row.row.sample_number);
    setOpenAlert(true);
  };

  const handleDeleteRow = () => {
    const url = ApiURL + "delete_sampleData";
    axios
      .post(url, {
        sample_number: deleteRow,
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((data) => {
        if (data.data.data.user > 0) {
          setMessage(true);
          setShow(true);
          setMsg("Deleted  successfully");
          getOpenSampleViewData();
        }
      });
    setOpenAlert(false);
  };

  const handleDocEdit = (items) => {
    setSelectupdateVisitData(1);
    let sampleType = items.row.operation_id;
    const selectUrl = ApiURL + "get_selected_sampleData";
    axios
      .get(selectUrl, {
        params: {
          sample_number: Number(items.row.sample_number),
          sampleType: items.row.operation_id,
        },
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.user) {
          setSelectVisitData(res.data.data.user);
          setCurrFieldChange(sampleType);
        }
        setOpenDoc(true);
        setOpen(true);
      });
  };

  const onHandleClosed = () => {
    setShow(false);
  };
  function generateRowId(row) {
    const hash = Object.values(row).join("-");
    return `row-${hash}`;
  }
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{
            marginTop: "24px",
            width: "100%",
            alignItems: "center",
            height: 500,
          }}
        >
          <DataGrid
            rows={sampleDataView.map((items, index) => ({
              ID: index + 1,
              operator: items.name,
              sample_number: items.sample_number,
              operation_id: items.operation_id,
              created_at: items.created_at,
              parent_id: items.parent_id,
            }))}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[10, 15, 25, 50, 100]}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            getRowId={generateRowId}
          />
        </Box>
      )}

      <Dialog
        open={openAlert}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Are you sure  you want to Delete?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleDeleteRow} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessPopup open={show} onClose={onHandleClosed} msg={msg} />
    </div>
  );
};

export default GetNewSampleData;
